import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import NewsList from 'components/NewsList'
import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'

export default function NewsPage({ data }) {
  const page = data.strapiNewsPage
  const { title } = page
  const customBanner = {
    pretitle: title,
  }
  return (
    <div>
      <div className='page page-news'>
        <BannerTitleOnlyBlock content={customBanner} padTop={true} />
        <NewsListWithFilter data={data} />
      </div>
    </div>
  )
}

const NewsListWithFilter = ({ data }) => {
  const { locale } = useLocalization()
  const { allStrapiNewsPost, allStrapiNewsPostCategory } = data
  const posts = allStrapiNewsPost.nodes

  const [activeCatSlug, setActiveCatSlug] = useState('')

  return (
    <>
      <div className='filter-bar container'>
        <div className='inner'>
          <Filter
            content={{ title: tr('all', locale), slug: '' }}
            activeCatSlug={activeCatSlug}
            setActiveCatSlug={setActiveCatSlug}
          />
          {allStrapiNewsPostCategory.nodes.map((cat, i) => (
            <Filter
              key={i}
              content={cat}
              activeCatSlug={activeCatSlug}
              setActiveCatSlug={setActiveCatSlug}
            />
          ))}
        </div>
      </div>
      <NewsList posts={posts} activeCatSlug={activeCatSlug} />
    </>
  )
}

const Filter = ({ content, activeCatSlug, setActiveCatSlug }) => {
  const activeClass = activeCatSlug === content.slug ? 'active' : ''
  return (
    <a
      className={`filter js-link ${activeClass}`}
      onClick={() => setActiveCatSlug(content.slug)}
    >
      {content.title}
    </a>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiNewsPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
    }
    allStrapiNewsPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        slug
        date
        bannerImageOnlyBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        newsPostCategory {
          title
          slug
        }
      }
    }
    allStrapiNewsPostCategory(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        title
        slug
      }
    }
  }
`
